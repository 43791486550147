export const ANNUAL = 'annual';
export const QUARTERLY = 'quarter';
export const FINANCIAL_METRICS = ['summary', 'income-statement', 'balance-sheet-statement', 'cash-flow-statement'];
export const SUMMARY_METRICS_MAP = {
    'Date': 'income-statement',
    'Revenue': 'income-statement',
    'Revenue Growth': 'income-statement', 
    'Net Income': 'income-statement', 
    'EPS': 'income-statement', 
    'Dividend per Share': 'income-statement',
    'Cash and short-term investments': 'balance-sheet-statement', 
    'Total debt': 'balance-sheet-statement',
    'Total shareholders equity': 'balance-sheet-statement', 
    'Free Cash Flow': 'cash-flow-statement', 
    '10K': 'income-statement'
}
export const METRICS_ORDER = {
    'summary': ['Date', 'Revenue', 'Revenue Growth', 'Net Income', 'EPS', 'Dividend per Share',
        'Cash and short-term investments', 'Total debt', 'Total shareholders equity', 'Free Cash Flow', '10K'],
    'income-statement': ['Date', 'Revenue', 'Revenue Growth', 'Cost of Revenue', 'Gross Profit', 'Gross Margin', 'SG&A Expense',
        'Operating Expenses', 'Operating Income', 'EBIT', 'EBITDA', 'Interest Expense', 'Income Tax Expense', 'Net Income', 'Net Profit Margin',
        'EPS', 'EPS Diluted', 'Dividend per Share'],
    'balance-sheet-statement': ['Date', 'Cash and short-term investments', 'Inventories', 'Total current assets',
        'Property, Plant & Equipment Net', 'Total non-current assets', 'Total assets', 'Total current liabilities',
        'Long-term debt', 'Total non-current liabilities', 'Total liabilities', 'Total shareholders equity'],
    'cash-flow-statement': ['Date', 'Operating Cash Flow', 'Investing Cash flow', 'Financing Cash Flow', 'Free Cash Flow']
};
export const BOLD_METRICS = ['Date', 'date', 'Gross Profit', 'Operating Income', 'Net Income', 'EPS', 'Total assets',
    'Total liabilities', 'Total shareholders equity', 'Free Cash Flow'];
export const PERCENT_METRICS = ['Revenue Growth', 'Gross Margin', 'Net Profit Margin'];
export const PROFILE_METRICS = ['companyName', 'mktCap', 'p/e', 'p/s', 'lastDiv', 'eps', 'revenue ttm',
     'net income ttm', 'beta', 'day range', 'earnings_date', 'exchange', 'volAvg', 'sector',
     'industry', 'ceo', 'country', 'ipoDate', 'fullTimeEmployees'];
export const LINK_METRICS = ['10K'];

export const PRICE_RANGE_PERIODS = ['1M', '3M', 'YTD', '1Y', '3Y', '5Y'];

export const LAST_HOME_PAGE_TICKER = "home page ticker";

export const DEFAULT_STATE = {
    ticker: '',
    realtime: {},
    PrePostData: {},
    profile: {},
    financials: {
        annual: {},
        quarter: {}
    },
    historical: {
        data: [],
        index: {}
    },
    current: {
        data: [],
        index: {}
    },
    stockNames: [],
    tickerRefreshIntervalId: "",
    title: "Invest Lucid Home",
    description: "Invest Lucid - Simplifying stocks"
};
