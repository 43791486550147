import React, { Component } from "react";
import TickerAutoComplete from "../../components/tickerAutoComplete/tickerAutoComplete";
import { Row, Col } from "reactstrap";
import { getStockNames } from "../../common/storageUtil";
import FinancialsDisplay from "./financialsDisplay";
import ProfileDisplay from "./profileDisplay";
import NewsDisplay from "./newsDisplay";
import { FINANCIAL_METRICS, ANNUAL, METRICS_ORDER, QUARTERLY, DEFAULT_STATE, PRICE_RANGE_PERIODS, LAST_HOME_PAGE_TICKER, SUMMARY_METRICS_MAP } from "./constants";
import PriceDisplay from "./priceDisplay";
import GoogleChart from "../../components/graph/googleChart";
import {
    fetchRealtimeData, fetchProfileData, fetchFinancialsData, fetchStockNews,
    fetchHistoricalDailyPrices, fetchCurrentMinutePrices, fetchKeyTickerData, fetchRelatedStocks, fetchStockRatings
} from "../../common/dataFetch";
import { GRAPH_TIME_PERIODS, THIRTY_DAYS_IN_SECONDS, PAGE_NAMES } from "../../common/constants";
import { sendAmplitudeData, AMPLITUDE_EVENT_TYPES } from '../../common/amplitude';
import { set_object } from "../../common/localStorage";
import { get_deep_clone } from "../../common/randomUtil";
import { watchlist_tickers_add_delete_url, MARKET_STATUS_URL, PRE_POST_REALTIME_PRICE_URL } from "../../common/url";
import { executeRestRequest } from "../../common/restService";
import { registerCall, deregisterCall } from '../../common/timerUtil';
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import RelatedStocks from "./relatedStocksDisplay";
import RatingsDisplay from "./ratingsDisplay";
import { withRouter } from '../../common/router';
import { withNavigateHook } from '../../common/navigate';

class Home extends Component {

    constructor(props) {
        super(props);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.HOME });
        this.state = get_deep_clone(DEFAULT_STATE);
    }

    static defaultProps = {
        ticker: 'META',
        PrePostData: {}
    };

    // Register for auto refresh of ticker
    registerTickerAutoRefresh = async () => {
        let marketStatusResponse = await executeRestRequest({ url: MARKET_STATUS_URL, method: 'GET' });
        let marketStatusData = marketStatusResponse.data;
        if ((marketStatusData || null) && marketStatusData['is open']) {
            let marketClosingTime = marketStatusData['to close time'];
            let refreshIntervalTime = 20  // 20 Seconds
            let fiveMinutesBufferTime = 5 * 60;
            let tickerRefreshIntervalId = registerCall(this.startAutoRefresh, refreshIntervalTime, marketClosingTime + fiveMinutesBufferTime);
            this.setState({ tickerRefreshIntervalId: tickerRefreshIntervalId });
        }
    }

    getPrePostPrice = async (ticker) => {
        let url = PRE_POST_REALTIME_PRICE_URL;
        let requestData = {
            url: url + ticker,
            method: 'GET'
        };
        let prices = await executeRestRequest(requestData);
        let ticker_data = prices.data.tickers[ticker];
        if('pre-post-market' in ticker_data) {
            let pre_post_data = prices.data.tickers[ticker]['pre-post-market'];
            let objToSet = {"PrePostPrice": pre_post_data.price, "PrePostTime": pre_post_data.timestamp }
            this.setState({"PrePostData" : objToSet})
        }
    }

    componentDidMount = async () => {
        // Read the ticker value from the URL
        let ticker = this.props.router.params.ticker;


        // If ticker not found in the URL, then get the last searched ticker
        if (!(ticker || null)) {
            //ticker = await get_object(LAST_HOME_PAGE_TICKER);
            // If last searched ticker not found then use the default ticker
            if (!(ticker || null)) {
                ticker = this.props.ticker;
            }
             this.renderTickerData(ticker);
             this.registerTickerAutoRefresh();
            this.props.navigation('/home/' + ticker);
        } else {
            this.renderTickerData(ticker);
            this.registerTickerAutoRefresh();
        }
        let marketStatusResponse = await executeRestRequest({ url: MARKET_STATUS_URL, method: 'GET' });
        let marketStatusData = marketStatusResponse.data;
        if ((marketStatusData || null) && !marketStatusData['is open']) {
            await this.getPrePostPrice(ticker);
        }
        //setWindowTitle(INVEST_LUCID_TITLE);
    }

    componentWillUnmount = () => {
        this.stopAutoRefresh();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.router.params || null) {
            if (newProps.router.params.ticker || null) {
                if (newProps.router.params.ticker !== this.state.ticker) {
                    this.renderTickerData(newProps.router.params.ticker);
                }
            }
        }
        //setWindowTitle(INVEST_LUCID_TITLE);
    }

    startAutoRefresh = () => {
        if (this.state.ticker || null)
            this.fetchTickerData(this.state.ticker);
    }

    stopAutoRefresh() {
        if (this.state.tickerRefreshIntervalId !== "")
            deregisterCall(this.state.tickerRefreshIntervalId);
    }

    addTickerToWatchList = async (params) => {
        let watchListId = params.id;
        let ticker = params.ticker;
        let url = watchlist_tickers_add_delete_url(watchListId);
        let requestData = {
            url: url,
            method: 'PUT',
            payload: { 'tickers': [ticker] }
        }
        let res = await executeRestRequest(requestData);
        this.props.refreshWatchList(res.data);
    }

    getWatchListName() {
        let ticker = this.state.ticker;
        let watchLists = this.props.watchLists;
        let watchListName = '';
        if ((ticker || null) && (watchLists || null)) {
            for (let i = 0; i < watchLists.length; i++) {
                if (watchLists[i].tickers.includes(ticker)) {
                    watchListName = watchLists[i].name;
                    break;
                }
            }
        }
        return watchListName;
    }

    renderTickerData = async (ticker) => {
        this.setState({ ticker: ticker });
        set_object(LAST_HOME_PAGE_TICKER, ticker, THIRTY_DAYS_IN_SECONDS);
        this.fetchAll(ticker);
        let stockNames = await getStockNames();
        let stockNews = await fetchStockNews(ticker);
        let stockRatings = await fetchStockRatings(ticker);
        let relatedStocks = await fetchRelatedStocks(ticker);
        this.setState({
            stockNames: stockNames,
            news: stockNews,
            relatedStocks: relatedStocks,
            stockRatings: stockRatings['tickers'][ticker]['ratings']
        });
    }

    fetchRelatedStocks = async ticker => {

    }

    fetchAll = async ticker => {
        //this.fetchTickerData(ticker);
        //this.fetchProfile(ticker);
        this.fetchKeyData(ticker);
        this.fetchCurrent(ticker);
        this.fetchFinancials(ticker, ANNUAL);
        this.fetchFinancials(ticker, QUARTERLY);
        this.getPrePostPrice(ticker);
        //this.fetchHistorical(ticker);
    }

    fetchKeyData = async ticker => {
        let data = await fetchKeyTickerData(ticker);
        let profile = data.profile;
        if (profile || null) {
            //profile['p/e'] = profile['pe']
            if ('mktCap' in profile && 'revenue ttm' in profile) {
                if (profile['mktCap'] > 0 && profile['revenue ttm'] > 0)
                    profile['p/s'] = profile['mktCap'] / profile['revenue ttm'];
            }
            profile['yahoo link'] = 'https://finance.yahoo.com/quote/' + ticker;
        }
        let res = await this.processHistoricalData(data.historical);
        let chartData = res[0], rangeMap = res[1], indexObj = res[2];
        let currentPrice = 0;
        let delisted = false;
        if ('realtime' in data) {
            currentPrice = data.realtime['Latest Price'];
            if ('Latest Change' in data.realtime && data.realtime['Latest Change'] === 0 && !profile.isActivelyTrading)
                delisted = true;

        }
        if ((currentPrice || null) && currentPrice > 0) {
            if (profile || null) {
                let rangeObj = {};
                profile['range'] = rangeObj;
                for (let key in rangeMap) {
                    rangeObj[key] = (currentPrice - rangeMap[key]) / rangeMap[key];
                };
            }
        }
        this.setState({
            realtime: data.realtime,
            profile: profile,
            delisted: delisted,
            historical: {
                data: chartData,
                index: indexObj
            }
        });
    }

    fetchCurrent = async ticker => {
        let data = await fetchCurrentMinutePrices(ticker);
        if (data.length < 1)
            return;

        const arr = Object.keys(data).map(x => x.split(' ')[0]);
        let dt_1w = new Date();
        dt_1w.setDate(dt_1w.getDate() - 7);
        let dt_4w = new Date();
        dt_4w.setDate(dt_4w.getDate() - 28);
        let dt_8w = new Date();
        dt_8w.setDate(dt_4w.getDate() - 56);
        let indexObj = { '1D': 0, '1W': 0, '4W': 0, '8W': 0 };
        let set_1d = false;
        let set_1w = false;
        let set_4w = false;
        let set_8w = false;
        let last_dt = new Date(arr[arr.length - 1]);
        for (let i = arr.length - 1; i > 0; i--) {
            let dt = new Date(arr[i]);
            if (!set_1d && dt < last_dt) {
                indexObj['1D'] = i + 1;
                set_1d = true;
            }
            if (!set_1w && dt < dt_1w) {
                indexObj['1W'] = i + 1;
                set_1w = true;
            }
            if (!set_4w && dt < dt_4w) {
                indexObj['4W'] = i + 1;
                set_4w = true;
            }
            if (!set_8w && dt < dt_8w) {
                indexObj['8W'] = i + 1;
                set_8w = true;
            }
        }

        //let del_keys = Object.keys(data).slice(0, index_4w + 1);
        //del_keys.forEach(k => delete data[k]);

        let chartData = [];
        //let dayLow = 1000000.0;
        //let dayHigh = 0;
        for (let key in data) {
            chartData.push([new Date(key.replace(' ', 'T')), data[key]]);
        }
        /**
        let daysRange = await this.getDaysRange(dayLow, dayHigh);
        let profile = this.state.profile;

        if (profile || null) {
            profile['day range'] = daysRange;
        }
        */
        this.setState({
            current: {
                data: chartData,
                index: indexObj,
                //profile: profile
            }
        });
    }

    getDaysRange = async (dayLow, dayHigh) => {
        let currentPrice = this.state.realtime['Latest Price'];
        if ((currentPrice || null) && currentPrice > 0) {
            if (currentPrice < dayLow)
                dayLow = currentPrice;
            if (currentPrice > dayHigh)
                dayHigh = currentPrice;
        }
        return '$' + dayLow.toFixed(2).toString() + ' - $' + dayHigh.toFixed(2).toString();
    }

    updateRanges = async (rangeMap) => {
        let currentPrice = this.state.realtime['Latest Price'];
        if ((currentPrice || null) && currentPrice > 0) {
            let profile = this.state.profile;

            if ((profile || null) && Object.keys(profile).length > 0 && profile['range']) {
                let rangeObj = profile['range'];
                for (let key in rangeMap) {
                    rangeObj[key] = (currentPrice - rangeMap[key]) / rangeMap[key];
                };
                this.setState({ profile: profile });
            }
        }
    }

    fetchHistorical = async ticker => {
        let data = await fetchHistoricalDailyPrices(ticker);
        if (data.length < 1)
            return;

        let { chartData, indexObj, priceReturnObj } = this.processHistoricalData(data);
        this.setState({
            historical: {
                data: chartData,
                index: indexObj
            }
        });
        this.updateRanges(priceReturnObj);
    }

    fetchTickerData = async (ticker) => {
        let data = await fetchRealtimeData(ticker);
        this.setState({
            realtime: data
        });
    }

    fetchProfile = async (ticker) => {
        let profile = await fetchProfileData(ticker);

        let calculatePE = () => {
            if ('Latest Price' in this.state.realtime) {
                let price = this.state.realtime['Latest Price'];
                let financials = this.state.financials.quarter;
                if (FINANCIAL_METRICS[0] in financials) {
                    let incomeStmt = financials[FINANCIAL_METRICS[0]];
                    if ('EPS' in incomeStmt) {
                        let epsArr = incomeStmt.EPS.split(',').map(x => +x);
                        if (epsArr.length > 3) {
                            let eps = epsArr[0] + epsArr[1] + epsArr[2] + epsArr[3];
                            if (eps > 0)
                                profile['p/e'] = price / eps;
                        }
                    }
                }
            }
        };
        calculatePE();
        if ('mktCap' in profile && 'revenue ttm' in profile) {
            if (profile['mktCap'] > 0 && profile['revenue ttm'] > 0)
                profile['p/s'] = profile['mktCap'] / profile['revenue ttm'];
        }
        profile['range'] = {};
        this.setState({ profile: profile });
    }

    fetchFinancials = async (symbol, period) => {
        let ticker = symbol || null ? symbol : this.state.ticker;
        let data = await fetchFinancialsData(ticker, period);

        if (period === "quarter")
            delete data[FINANCIAL_METRICS[1]]['10K'];

        let calculateRevenueGrowth = () => {
            let revenueArr = data[FINANCIAL_METRICS[1]]['Revenue'].split(',');
            let compareGap = period === ANNUAL ? 1 : 4;
            let revenueGrowthArr = [];
            revenueArr = revenueArr.map(a => Math.abs(a));
            for (let i = 0; i < (revenueArr.length - compareGap); i++) {
                let prevRevenue = Math.abs(revenueArr[i + compareGap]);
                let growth = (Math.abs(revenueArr[i]) - prevRevenue) / prevRevenue;
                revenueGrowthArr.push(growth);
            }
            for (let i = 0; i < compareGap; i++)
                revenueGrowthArr.push('');
            data[FINANCIAL_METRICS[1]]['Revenue Growth'] = revenueGrowthArr.join(',');
        };

        if (!data[FINANCIAL_METRICS[1]])
            return;
        if (data[FINANCIAL_METRICS[1]]['Revenue'])
            calculateRevenueGrowth();
        let summaryData = {};

        for (let k = 0; k < METRICS_ORDER['summary'].length; k++) {
            let metric = METRICS_ORDER['summary'][k];
            
            if (metric in data[SUMMARY_METRICS_MAP[metric]])
                summaryData[metric] = data[SUMMARY_METRICS_MAP[metric]][metric];
        }
        data['summary'] = summaryData;
        
        let financials = this.state.financials;
        financials[period] = data;
        this.setState({ financials: financials });
    }

    searchTickerHandler = async symbol => {
        let ticker = symbol.toUpperCase();
        let stateObjToSet = get_deep_clone(DEFAULT_STATE);
        stateObjToSet.ticker = ticker;
        this.fetchAll(ticker);
        this.setState(stateObjToSet);
        set_object(LAST_HOME_PAGE_TICKER, ticker, THIRTY_DAYS_IN_SECONDS);
        this.props.navigation('/home/' + ticker);
    }

    updateHistorical = () => {
        //this.fetchHistorical(this.state.ticker);
    }

    getLookBackDates = async (periods) => {
        let lookBackDates = [];
        for (let i in periods) {
            let period = periods[i];
            let dt = new Date();
            if (period === 'YTD') {
                dt = new Date(dt.getFullYear(), 0, 1);
            } else if (period.indexOf('D') > 0) {
                let days = period.slice(0, -1);
                dt.setDate(dt.getDate() - days);
            } else if (period.indexOf('W') > 0) {
                let weeks = period.slice(0, -1);
                dt.setDate(dt.getDate() - (weeks * 7));
            } else if (period.indexOf('M') > 0) {
                let months = period.slice(0, -1);
                dt.setMonth(dt.getMonth() - months);
            } else if (period.indexOf('Y') > 0) {
                let years = period.slice(0, -1);
                dt.setYear(dt.getFullYear() - years);
            } else {
                break;
            }
            lookBackDates.push([dt.toDateString(), period]);
        }
        return lookBackDates.sort((a, b) => { return new Date(a[0]) > new Date(b[0]) ? 1 : -1 });
    }
    
    processHistoricalData = async data => {
        let lookBackDates = await this.getLookBackDates(GRAPH_TIME_PERIODS);
        let lookBackIndex = 0;
        let indexObj = {};
        let chartData = [];
        let lookBackDate = new Date(lookBackDates[lookBackIndex][0]);
        let priceReturnDates = await this.getLookBackDates(PRICE_RANGE_PERIODS);
        let priceReturnIndex = 0;
        let priceRangeMap = {};
        let priceReturnDate = new Date(priceReturnDates[priceReturnIndex][0]);
        let i = 0;
        for (let key in data) {
            chartData.push([new Date(key + 'T00:00'), data[key]]);
            if (priceReturnIndex < priceReturnDates.length && new Date(key) >= priceReturnDate) {
                priceRangeMap[priceReturnDates[priceReturnIndex][1]] = data[key];
                priceReturnIndex++;
                if (priceReturnIndex < priceReturnDates.length)
                    priceReturnDate = new Date(priceReturnDates[priceReturnIndex][0]);
            }
            if (lookBackIndex < lookBackDates.length && new Date(key) >= lookBackDate) {
                indexObj[lookBackDates[lookBackIndex][1]] = i;
                lookBackIndex++;
                if (lookBackIndex < lookBackDates.length)
                    lookBackDate = new Date(lookBackDates[lookBackIndex][0]);
            }
            i++;
        }
        return [chartData, priceRangeMap, indexObj];
    }

    openTicker = async ticker => {
        this.props.navigation('/home/' + ticker);
    }

    render = () => {

        let stockPriceData = {
            symbol: this.state.ticker,
            name: this.state.stockNames[this.state.ticker]
        };
        stockPriceData = { ...stockPriceData, ...this.state.realtime, ...this.state.PrePostData };
        let price = null;
        if (this.state.realtime || null)
            price = this.state.realtime['Latest Price'];
        let desc = '';
        if ((this.state.profile || null) && Object.keys(this.state.profile).includes('description')) {
            desc = <Col>{this.state.profile['description']}</Col>;
        }

        let watchListName = this.getWatchListName();
        let watchLists = this.props.watchLists;

        return <div>
            <Helmet onChangeClientState={(newState) =>
                this.setState({
                    title: this.state.ticker + ' ' + this.state.realtime['Latest Price']
                        + ' (' + this.state.realtime['Latest Change'] + ') '
                        + this.state.stockNames[this.state.ticker],
                    description: 'Find the latest ' + this.state.stockNames[this.state.ticker] +
                        ' (' + this.state.ticker +
                        ') stock price, financials, hostorical prices, news and other information to help you make decision to invest.'
                })}>
                <meta charSet="utf-8" />
                <title>{this.state.title}</title>
                <meta name="description" content={this.state.description} />
            </Helmet>
            <Row className={isMobile ? "mx-1" : ""}>
                <Col>
                    <Row className="justify-content-center mt-2" >
                        <Col xl={7} md={10} xs={12}>
                            <TickerAutoComplete onSubmit={this.searchTickerHandler} buttonName="Go" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-4 mx-0 px-0">
                        <Col xl={5} md={12} xs={12} className="mb-1 mx-0 px-0">
                            <PriceDisplay tickerPriceObj={stockPriceData} delisted={this.state.delisted} />
                            <ProfileDisplay profile={this.state.profile}
                                price={price}
                                watchList={watchListName}
                                watchLists={watchLists}
                                addTickerToWatchList={this.addTickerToWatchList}
                                ticker={stockPriceData.symbol} />
                        </Col>
                        <Col xl={6} md={12} xs={12} className="mb-1 mx-0 px-0 py-0">
                            <GoogleChart ticker={this.state.ticker}
                                historical={this.state.historical}
                                current={this.state.current}
                                updateHistorical={this.updateHistorical} />
                        </Col>
                        {
                            isMobile ? '' :
                                <Col xl={1} md={12} xs={12} className="mb-1 mx-0">
                                    <RelatedStocks data={this.state.relatedStocks} 
                                    openTicker={this.openTicker} />
                                </Col>
                        }
                        <Col xl={8} md={12} xs={12} className="mt-1 mx-0 px-0">
                            <NewsDisplay news={this.state.news} />
                        </Col>
                        <Col xl={4} md={12} xs={12} className="mt-1 mx-0 px-0">
                            <RatingsDisplay ratings={this.state.stockRatings} />
                        </Col>
                        <Col xl={12} md={12} xs={12} className="mt-2 mx-0 px-0">
                            <FinancialsDisplay ticker={this.state.ticker} annual={this.state.financials[ANNUAL]}
                                quarter={this.state.financials[QUARTERLY]}
                                fetchFinancials={this.fetchFinancials} /></Col>
                    </Row>
                    <Row className={"mt-2 mb-4 text-primary border rounded " + (isMobile ? "" : "mx-0")}>
                        {desc}
                    </Row>
                </Col>
            </Row>
        </div>;
    }
}

export default withNavigateHook(withRouter(Home));